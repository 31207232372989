import React, { useRef } from 'react';
import About from "./components/About";
import Banner from "./components/Banner";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import TradeResult from "./components/TradeResult";

function IntialPage() {

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const resultRef = useRef(null);
  const contactRef = useRef(null);

  return (
   <>
      <Header homeRef={homeRef} aboutRef={aboutRef} resultRef={resultRef} contactRef={contactRef} />
      <Banner ref={homeRef} />
      <About ref={aboutRef} />
      <TradeResult ref={resultRef} />
      <ContactUs ref={contactRef} />
      <Footer  />
   </>
  );
}

export default IntialPage;
