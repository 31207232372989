import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';
import BannerImg from '../assets/images/tradeassist-about.jpg'

const About = forwardRef((props, ref) =>  {
    return (
        <AboutSection ref={ref}>
            <AboutContent>
                <AboutContentCenter>
                    <h2>ABOUT TRADE ASSIST</h2>
                    <p>Tradeassist has interests in sharing knowledge of trading on share market with technical and fundamental analysis by our experts. We will assist people to understand basic things about trading for educational purpose.</p>
                </AboutContentCenter>
            </AboutContent>
            <AboutImage>
                <img src={BannerImg} alt='Trade Assist' title='Trade Assist' />
            </AboutImage>
        </AboutSection>
    );
});

const AboutSection = styled.div`
    display: flex;
    background-color: ${colors.primary_01};
    position: relative;
`
const AboutImage = styled.div`
    height: calc(100svh);
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
const AboutContent = styled.div`
    height: calc(100svh);
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    background: ${colors.black_transparent_1};
`
const AboutContentCenter = styled.div`
    background: ${colors.black_transparent_3};
    text-align: center;
    width: 50%;
    padding: 50px;
    margin: auto;
    @media(max-width: 767px){
    width: 80%;
      padding: 18px;
    }
    h2 {
        font-size: 32px;
        color: ${colors.gray_07};
        letter-spacing: 3px;
        margin: 0;
        @media(max-width: 767px){
            font-size: 24px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.gray_07};
        letter-spacing: 1px;
    }
`
export default About;