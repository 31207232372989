export const colors = {
    primary_01: '#00131e',
    primary_02: '#022f45',
    primary_03: '#055173',
    primary_04: '#0b75a5',
    primary_05: '#129cd9',
    primary_06: '#64c2fe',
    primary_07: '#c8e4fe',

    secondary_01: '#401201',
    secondary_02: '#732705',
    secondary_03: '#ab3e0c',
    secondary_04: '#e65614',
    secondary_05: '#fd8b72',
    secondary_06: '#fec5bc',
    secondary_07: '#ffedeb',

    gray_01: '#101112',
    gray_02: '#2b2c2d',
    gray_03: '#4a4c4e',
    gray_04: '#6c6f71',
    gray_05: '#8f9397',
    gray_06: '#b6b9bd',
    gray_07: '#dfe1e2',

    success_01: '#CFF2D9',
    success_02: '#4ED073',
    success_03: '#2BA24C',

    warning_01: '#D1FF99',
    warning_02: '#B6FF5C',
    warning_03: '#89FC00',

    danger_01: '#FFB1AD',
    danger_02: '#FF8B85',
    danger_03: '#FF5349',

    white: '#FFFFFF',
    black: '#000000',

    black_transparent_1: 'rgba(0,0,0,.4)',
    black_transparent_2: 'rgba(0,0,0,.6)',
    black_transparent_3: 'rgba(2,19,30,.9)',

    white_transparent_1: 'rgba(255,255,255,.1)',
    white_transparent_2: 'rgba(255,255,255,.3)',
    white_transparent_3: 'rgba(255,255,255,.5)',
    white_transparent_4: 'rgba(255,255,255,.7)',
    
  };