import React from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';

const Footer = () => {
    return (
        <FooterSection>
            <CopyRightTxt>Copyright 2024 by TRADE ASSIST</CopyRightTxt>
        </FooterSection>
    );
};

const FooterSection = styled.footer`
   background: ${colors.primary_02};
   padding: 24px 0;
`
const CopyRightTxt = styled.div`
   text-align: center;
   color: ${colors.gray_07};
   font-size: 14px;
   letter-spacing: 2px;
`

export default Footer;