import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';
import BannerImg from '../assets/images/tradeassist.jpg'

const Banner = forwardRef((props, ref) => {
    return (
        <BannerSection ref={ref}>
            <BannerImage>
                <img src={BannerImg} alt='Trade Assist' title='Trade Assist' />
            </BannerImage>
            <BannerContent>
                <BannerContentCenter>
                    <h1>TRADE ASSIST</h1>
                    <h3>We assist you to earn, Everyone deserves economic freedom.</h3>
                    <p>Discover the power of trading and unlock your potential as a trader without expert-led courses.</p>
                </BannerContentCenter>
            </BannerContent>
        </BannerSection>
    );
});

const BannerSection = styled.div`
    display: flex;
    background-color: ${colors.primary_02};
    position: relative;
`
const BannerImage = styled.div`
    height: calc(100svh - 70px);
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
const BannerContent = styled.div`
    height: calc(100svh - 70px);
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    background: ${colors.black_transparent_1};
`
const BannerContentCenter = styled.div`
    background: ${colors.black_transparent_3};
    text-align: center;
    width: 100%;
    padding: 24px;
    h1 {
        font-size: 32px;
        color: ${colors.gray_07};
        letter-spacing: 3px;
        margin: 0 0 10px;
    }
    h3 {
        font-size: 21px;
        font-weight: 400;
        color: ${colors.gray_07};
        letter-spacing: 1px;
        margin: 0;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.gray_07};
        letter-spacing: 1px;
    }
`
export default Banner;