import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';
import Slider from "react-slick";
import Banner1 from '../assets/images/result-1.jpg'
import Banner2 from '../assets/images/result-2.jpg'
import Banner3 from '../assets/images/result-3.jpg'
import Banner4 from '../assets/images/result-4.jpg'

const TradeResult = forwardRef((props, ref) => {


    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1240,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
        ]
    };


    return (
        <TradeResultSection ref={ref}>
            <TradeResultSectionMiddle>
                <h2>TRADING RESULT</h2>
                <TradeResultListContainer>
                    <Slider {...settings} className='trade-slider'>
                    <TradeResultList>
                        <TradeResultListInner>
                            <img src={Banner1} alt='' title='CHARTS BEFORE ENTRY' />
                            <p>CHARTS BEFORE ENTRY</p>
                        </TradeResultListInner>
                    </TradeResultList>
                    <TradeResultList>
                        <TradeResultListInner>
                            <img src={Banner2} alt='CHARTS AFTER ENTRY' title='CHARTS AFTER ENTRY' />
                            <p>CHARTS AFTER ENTRY</p>
                        </TradeResultListInner>
                    </TradeResultList>
                    <TradeResultList>
                        <TradeResultListInner>
                            <img src={Banner3} alt='PROFIT' title='PROFIT' />
                            <p>PROFIT</p>
                        </TradeResultListInner>
                    </TradeResultList>
                    <TradeResultList>
                        <TradeResultListInner>
                            <img src={Banner4} alt='REVIEW' title='REVIEW' />
                            <p>REVIEW</p>
                        </TradeResultListInner>
                        
                    </TradeResultList>
                    </Slider>
                </TradeResultListContainer>
            </TradeResultSectionMiddle>

        </TradeResultSection>
    );
});

const TradeResultSection = styled.div`
    background-color: ${colors.primary_02};
    position: relative;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 767px){
        height: auto;
        padding: 32px 0;
    }
`
const TradeResultSectionMiddle = styled.div`
    position: relative;
    width: calc(100% - 50px);

    @media(max-width: 767px){
            width: calc(100% - 24px);
        }

    h2 {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 3px;
        color: ${colors.gray_07};
        margin: 0 0 34px;
        text-align: center;
        @media(max-width: 767px){
            font-size: 24px;
        }
    }
`
const TradeResultListContainer = styled.div`
    
`
const TradeResultList = styled.div`
    img {
        max-width: 100%;
    }
`
const TradeResultListInner = styled.div`
    margin: 10px;
    background: ${colors.primary_01};
    @media(max-width: 767px){
            margin: 0;
        }
    img {
        max-width: 100%;
    }
        p{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: ${colors.gray_07};
            padding: 0 0 12px;
            letter-spacing: 1px;
        }
`

export default TradeResult;