import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';
import { useForm } from 'react-hook-form';

import Whatsapp from '../assets/images/whatsapp.png'
import Telegram from '../assets/images/telegram.png'
import Upi from '../assets/images/upi.png'


const ContactUs = forwardRef((props, ref) => {
    
    const { register: registerForm, handleSubmit, reset, formState: { errors }, setValue, watch } = useForm({
        mode: 'onChange',
    });

    const onSubmit = async (leadData) => {

        console.log(leadData)
        
        // try {
        // } catch (error) {
        // }
    };


    return (
        <ContactUsSection ref={ref}>
            <div className='container'>
                <h2>CONTACT US</h2>
                  <ContactUsAddress>
                    <p><a href='tel:+918667312398'>+91 86673 12398</a></p>
                    <p><a href='mailtop:kovan@tradeassist.in'>kovan@tradeassist.in</a></p>
                    <p>Mon - Fri : 08:00am to 07:00pm</p>
                  </ContactUsAddress>

                  <Payment>
                    <a href='https://t.me/TradeAssistfree' target='_blank'>
                        <img src={Telegram} alt='Telegram' title='Telegram' />
                    </a>
                    {/* <a href='https://t.me/+V-KqbBqpylNhZmY1' target='_blank'>
                        <img src={Telegram} alt='Telegram' title='Telegram' />
                    </a> */}
                    <a href='https://wa.me/+919952626644' target='_blank'>
                        <img src={Whatsapp} alt='Whatsapp' title='Whatsapp' />
                    </a>
                    <a href='https://www.upilinks.in/payment-link/upi1240045577' target='_blank'>
                        <img src={Upi} alt='UPI Payment' title='UPI Payment' />
                    </a>
                  </Payment>

                  <FormSection>
                  <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-group'>
                            <label>Name</label>
                            <input maxLength={'50'} className={errors.name ? 'error' : ''} type="text" {...registerForm('name', { required: true, pattern: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/, })} />
                            {errors.name && <span className='error-text'>Enter Full Name</span>}
                        </div>
                        <div className='form-group'>
                            <label>Phone</label>
                            <input maxLength={'10'} className={errors.phone ? 'error' : ''} type="tel" {...registerForm('phone', { required: true, pattern: /^[0-9]{6}$/ })} />
                            {errors.phone && <span className='error-text'>Enter Phone Number</span>}
                        </div>
                        <div className='form-group'>
                            <label>Email</label>
                            <input className={errors.email ? 'error' : ''} type="text" {...registerForm('email', { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })} />
                        {errors.email && <span className='error-text'>Email is required</span>}
                        </div>
                        <div className='form-group'>
                            <label>Message</label>
                            <textarea className={errors.message ? 'error' : ''} {...registerForm('message', { required: true })}></textarea>
                            {errors.message && <span className="error-text">Email Message is Required</span>}
                        </div>
                        <div className='form-group'>
                            <Button>REQUEST CALL NOW</Button>
                        </div>
                        </form>
                  </FormSection>
            </div>
        </ContactUsSection>
    );
});

const ContactUsSection = styled.div`
    background-color: ${colors.primary_01};
    padding: 52px 0;
    
    h2 {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 3px;
        color: ${colors.gray_07};
        margin: 0 0 34px;
        text-align: center;
    }
`
const Payment = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    a {
        margin: 0 12px;
    }
`
const ContactUsAddress = styled.div`
    p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 3px;
        color: ${colors.primary_07};
        margin: 0 0 12px;
        text-align: center;
        a {
            text-decoration: none;
            color: ${colors.primary_07};
        }
    }
`
const FormSection = styled.div`
   max-width: 700px;
   margin: auto;
`
const Button = styled.button`
   background: ${colors.primary_04};
   color: ${colors.white};
   width: 100%;
   padding: 16px 30px;
   border: 0;
   font-size: 15px;
   letter-spacing: 3px;
   font-weight: 500;
   border-radius: 5px;
   cursor: pointer;
`

export default ContactUs;