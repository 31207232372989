import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../common/constant';
import Logo from '../assets/images/logo.png'

function Header({ homeRef, aboutRef, resultRef, contactRef }) {

    const handleMenuClick = (ref) => {
        SetMenuActive(false)
        window.scrollTo({
          top: ref.current.offsetTop - 60,
          behavior: 'smooth', // for smooth scrolling
        });
      };

    const [menuActive, SetMenuActive] = useState(false)

    const menuClick = () => SetMenuActive(!menuActive)

    return (
       <HeaderSection>
            <div className='container'>
                <HeaderSectionGrid>
                    <LogoSection>
                        <img src={Logo} alt='Trade Assist' title='Trade Assist' />
                        <span>Trade Assist</span>
                    </LogoSection>
                    <MenuSection>
                        <MenuClickMobile onClick={() => menuClick()} className={menuActive ? 'active' : ''}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </MenuClickMobile>
                        <MenuSectionMenu className={menuActive ? 'active' : ''}>
                            <MenuLinks onClick={() => handleMenuClick(homeRef)}>HOME</MenuLinks>
                            <MenuLinks onClick={() => handleMenuClick(aboutRef)}>ABOUT US</MenuLinks>
                            <MenuLinks onClick={() => handleMenuClick(resultRef)}>TRADING RESULT</MenuLinks>
                            <MenuLinks onClick={() => handleMenuClick(contactRef)}>CONTACT US</MenuLinks>
                        </MenuSectionMenu>
                        
                    </MenuSection>
                </HeaderSectionGrid>
            </div>
       </HeaderSection>
    );
}

const HeaderSection = styled.header`
    background: ${colors.primary_01};
    padding: 12px 0;
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: 1px 1px 2px #000;
`
const MenuClickMobile = styled.div`
    display: none;
    @media(max-width: 767px){
        display: flex;
        flex-direction: column;
    }
    span {
        width: 32px;
        height: 2px;
        background: ${colors.gray_07};
        margin: 4px 0;
        position: relative;
        transition: all 0.5s easy-in-out;
    }
        &.active{
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: 6px;
                }
                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(3) {
                    top: -4px;
                    transform: rotate(-45deg);
                }
            }
        }
`
const HeaderSectionGrid = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const LogoSection = styled.div`
    display: flex;
    img {
    max-width: 54px;
    }
    span {
        font-weight: 900;
        font-size: 34px;
        color: ${colors.gray_07};
        margin-left: 10px;
        letter-spacing: 1px;
        @media(max-width: 767px){
            display: none;
        }
    }
`
const MenuSection = styled.div`
    display: flex;
`
const MenuSectionMenu = styled.div`
    display: flex;
    @media(max-width: 767px){
            display: none;
        }

    &.active {
        @media(max-width: 767px){
        display: flex;
        width: 100%;
        height: calc(100svh - 64px);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(160deg, ${colors.primary_02} 0%, ${colors.primary_01} 100%);
        flex-direction: column;
        }
    }
`
const MenuLinks = styled.div`
    margin-left: 24px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    color: ${colors.gray_07};
    cursor: pointer;

    @media(max-width: 767px){
       padding: 24px 0;
       text-align: center;
       margin: 0;
       font-size: 21px;
    }
`

export default Header;